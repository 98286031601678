import request from '@/utils/request'
/**
 * 查询调查问卷列表
 */
export function questionnaireList (params) {
  return request({
    url: '/seller/questionnaire/list',
    method: 'get',
    params
  })
}
/**
 * 查询调查问卷调查结果列表
 */
export function surveyList (params) {
  return request({
    url: '/seller/questionnaire/surveyList',
    method: 'get',
    params
  })
}
/**
 * 查询调查问卷的调查结果详情
 */
export function getQuestionnaireResult (id) {
  return request({
    url: `/seller/questionnaire/getResult/${id}`,
    method: 'get'
  })
}
/**
 * 调查问卷详情查询
 */
export function getQuestionnaire (id) {
  return request({
    url: `/seller/questionnaire/${id}`,
    method: 'get'
  })
}
/**
 * 添加调查问卷
 *  @params params
 */
export function addQuestionnaire(params) {
  return request({
    url: '/seller/questionnaire',
    method: 'post',
    headers: {
      'Content-Type':"application/json"
    },
    data:params
  })
}
/**
 * 修改调查问卷
 *  @params params
 */

export function editQuestionnaire(params) {
  return request({
    url: '/seller/questionnaire',
    method: 'put',
    headers: {
      'Content-Type':"application/json"
    },
    data:params
  })
}
/**
 * 更新单个调查问卷状态
 *  @params params
 */

export function updateStatus(params) {
  return request({
    url: '/seller/questionnaire/updateStatus',
    method: 'put',
    headers: {
      'Content-Type':"application/json"
    },
    params:params
  })
}