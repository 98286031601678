<template>
  <div class="content">
    <h2 class="title">{{ form.questionnaire_title }}</h2>
    <p class="desc">&nbsp;&nbsp;&nbsp;&nbsp;{{ form.questionnaire_description }}</p>
    <div class="body">
      <div class="body-list">
        <div v-for="(item, index) in form.question_volist" :key="index" class="body-list-item">
          <!-- 问题名称 -->
          <p>{{ index + 1 }}、{{ item.question_title }}</p>
          <!-- 选项 -->
          <!-- 单选 -->
          <div class="answer" v-if="item.question_type == 'single_choice' || item.question_type == 'multiple_choice'">
            <p v-for="(question, itemIndex) in item.option_list" :key="itemIndex">
              <span v-if="!item.survey_result_detail_dolist">
                <el-checkbox :value="false"></el-checkbox>
              </span>
              <span v-else>
                <el-checkbox
                  :value="item.survey_result_detail_dolist.some(q => q.option_id === question.id)"></el-checkbox>
              </span>
              {{ question.option_name }}
            </p>
          </div>
          <!-- 单文本 -->
          <div v-if="item.question_type == 'single_text'">
            <p>{{ item.survey_result_detail_dolist ? item.survey_result_detail_dolist[0].question_answer : '' }}</p>
          </div>
          <!-- 多文本 -->
          <div v-if="item.question_type == 'multiple_text'">
            <p>{{ item.survey_result_detail_dolist ? item.survey_result_detail_dolist[0].question_answer : '' }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getQuestionnaireResult } from '@/api/questionnaire';
export default {
  name: 'questionnaireInfo',
  props: {
    question_id: {
      type: Number | String,
      default: ''
    }
  },
  data () {
    return {
      form: {}
    };
  },
  watch: {
    question_id: {
      handler (val, oldval) {
        if (val) {
          if (val) this.getQuestionnaire(val)
        }
      },
      immediate: true
    }
  },
  methods: {
    async getQuestionnaire (val) {
      this.form = await getQuestionnaireResult(val).then(res => res.data)
      console.log(this.form);
    }
  },
};
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 80vh;
  overflow: auto;
  background-color: #fff;
  padding-top: 20px;

  .title {
    text-align: center;
  }

  .body {
    margin-top: 20px;

    &-button {
      display: flex;
      flex-direction: column;
      margin-right: 6px;
    }

    &-list {
      flex: 1;
      padding: 20px;

      &-item {
        margin-bottom: 26px;
      }
    }
  }

  .answer {
    display: flex;
    margin-left: 20px;

    p {
      margin-right: 20px;
    }
  }
}
</style>