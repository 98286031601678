<template>
  <div class="finance-account">
    <en-table-layout :tableData="tableData.data" :loading="loading">
      <template slot="toolbar">
        <div style="width: 100%;margin-top:10px">
          <el-form-item label="提交时间" class="col-auto">
            <el-date-picker style="width: 220px" v-model="advancedForm.order_time_range" type="daterange" align="center"
              size="medium" :editable="false" unlink-panels range-separator="-" start-placeholder="开始日期"
              end-placeholder="结束日期"></el-date-picker>
          </el-form-item>
          <el-form-item prop="name">
            <el-input style="width: 315px" size="medium" placeholder="请输入手机号搜索" v-model="params.member_mobile" clearable>
            </el-input>
          </el-form-item>
          <el-form-item class="col-auto">
            <el-button @click="searchEvent" type="primary" size="small">搜索</el-button>
          </el-form-item>
          <div class="buttonbc">
            <el-button style="position: absolute;right:0" @click="() => { $router.go(-1); }" size="small"
              class="back_btn">
              <img src="../../assets/back.png" alt="">
            </el-button>
          </div>
        </div>
      </template>

      <template slot="table-columns">
        <el-table-column label="提交人手机号">
          <template slot-scope="{row}">{{ row.member_mobile || row.member_account }}</template>
        </el-table-column>

        <el-table-column label="提交时间">
          <template slot-scope="{row}">{{ row.create_time | unixToDate }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="{row}">
            <el-button v-if="!row.enable_status" size="mini" type="primary" @click="infoNotice(row)">查看</el-button>
          </template>
        </el-table-column>
      </template>
      <el-pagination slot="pagination" @size-change="handlePageSizeChange" @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no" :page-sizes="MixinPageSizes" :layout="MixinTableLayout" background
        :page-size="tableData.page_size" :total="tableData.data_total"></el-pagination>
    </en-table-layout>

    <!--修改|新增-->
    <el-dialog-x-dialog :title="loadingtitle" :visible.sync="editVisible">
      <questionnaireInfo :question_id="question_id" @cancel="editVisible = false"></questionnaireInfo>
    </el-dialog-x-dialog>
  </div>
</template>

<script>
import XDialog from '@/components/x-dialog/x-dialog';
import { surveyList, getQuestionnaire } from '@/api/questionnaire';
import EnTableLayout from '../../../ui-components/TableLayout/src/main';
import questionnaireInfo from './components/questionnaire-info.vue'
export default {
  name: 'questionnaireDetail',
  components: { EnTableLayout, XDialog, questionnaireInfo },
  data () {
    return {
      question_id: '',
      editVisible: false,//审核页
      loadingtitle: '查看',//弹窗标题
      /** 列表loading状态 */
      loading: false,
      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 20,
        id: ''
      },
      /** 高级搜索数据 */
      advancedForm: {
        order_time_range: [],
      },
      /** 列表数据 */
      tableData: {},
    };
  },

  mounted () {
    this.params.id = this.$route.query.id;
    this.GET_AccountList();
  },
  methods: {
    /** 搜索事件触发 */
    searchEvent () {
      this.params = {
        ...this.params,
        ...this.advancedForm
      };
      delete this.params.start_update_time;
      delete this.params.end_update_time;
      if (
        this.advancedForm.order_time_range &&
        this.advancedForm.order_time_range.length
      ) {
        if (typeof this.advancedForm.order_time_range[0] === "string") {
          this.params.start_update_time =
            new Date(this.advancedForm.order_time_range[0]).getTime() / 1000;
          this.params.end_update_time =
            new Date(this.advancedForm.order_time_range[1]).getTime() / 1000 +
            86400;
        } else {
          this.params.start_update_time =
            this.advancedForm.order_time_range[0].getTime() / 1000;
          this.params.end_update_time =
            this.advancedForm.order_time_range[1].getTime() / 1000 + 86400;
        }
      }
      delete this.params.order_time_range;
      this.params.page_no = 1;
      this.GET_AccountList();
    },
    /**关闭弹窗 */
    cancel () {
      this.editVisible = false
    },
    /**查看问卷点差详情弹窗 */
    async infoNotice (row) {
      this.question_id = row.id
      this.editVisible = true
    },
    /** 分页大小发生改变 */
    handlePageSizeChange (size) {
      this.params.page_size = size;
      this.GET_AccountList();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange (page) {
      this.params.page_no = page;
      this.GET_AccountList();
    },
    GET_AccountList () {
      this.loading = true;
      let params = this.params;
      surveyList(params).then(response => {
        this.loading = false;
        this.tableData = response.data;
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.finance-account-table-header {
  padding: 16px 16px 0;

  .line {
    font-size: 13px;
    color: #666;
  }
}

/deep/ .el-radio-group {
  .el-radio {
    margin-bottom: 0 !important;
  }
}

.en-table-layout .en-table-layout-toolbar .el-form-item {
  margin-bottom: 8px;
}

/deep/ .el-scrollbar__wrap {
  margin-right: -7px !important;
}

.dialog-footer {
  text-align: center;
  margin: 20px 0;
}
</style>
